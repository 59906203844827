import React from 'react';
import { Nav, Navbar, Container } from 'react-bootstrap';

export default function Menus(){
    return (
        <Navbar bg="dark" expand="lg" variant="dark" className='gap-3'>
        <Container fluid>
          <Navbar.Brand href="/" className='text-start'><img src='./logo.png' className='w-25' alt='Logo'/></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav"/>
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-right text-white">
            <Nav className='fs-4 font-monospace gap-3 text-uppercase text-muted'>
              <Nav.Link href="#service">Services</Nav.Link>
              <Nav.Link href="#product">Products</Nav.Link>
              <Nav.Link href="#testimonial">Testimonial</Nav.Link>
              <Nav.Link href="#about">About</Nav.Link>
              <Nav.Link href="#contact">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
}