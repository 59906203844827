import React, {useState} from 'react';
import './Contact.css';
import Item from '../Services/HelperScript';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {   } from '@fortawesome/free-solid-svg-icons'
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';

export default function ContactUs(){
    const[date] = useState(new Date().getFullYear());

    return(
        <>
            <div className='mx-3 pt-4' id="contact">
                <Item heading="Contact us" />
                <div className="row mb-0">
                    <div className='col-md-6 px-3 py-3'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.7832341437756!2d32.57435667399446!3d0.24028659975735445!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x177d973d5b0a8145%3A0x9a0350ded5d1dc52!2sTrue%20Computing%20Technology!5e0!3m2!1sen!2sug!4v1683899427238!5m2!1sen!2sug"
                        className='border border-2 bg-white px-1 py-1' id='map' title="Location on google map"></iframe> 
                    </div>
                    <div className='col-md-6 py-3 px-3'>
                        <p className='text-center'>For inquery, please reach True Computing Technology on:</p>
                        <div className='mt-2 text-start bg-white px-2 py-2'>
                            <div>
                                <span className='mx-2'> <FontAwesomeIcon icon="fas fa-location-arrow"></FontAwesomeIcon></span><span className='text-dark'>Plot 77A Kampala road, Kampala Plaza TR02</span>
                            </div>
                            <div>
                                <span className='mx-2'> <FontAwesomeIcon icon="fas fa-envelope"></FontAwesomeIcon></span><span className='text-dark'>opijackson17@pm.me</span>
                            </div>
                            <div>
                                <span className='mx-2'><FontAwesomeIcon icon="fas fa-mobile"></FontAwesomeIcon></span><span className='text-dark'>256 (0) 789 626405</span>
                            </div>
                            {/* <div>
                                <span className='mx-2'><FontAwesomeIcon icon="fa-brands fa-facebook"></FontAwesomeIcon></span><span><a href="https://facebook.com" className='text-decoration-none text-dark' rel='noopener noreferrer' target='_blank'>True Computing Technology</a></span>
                            </div> */}
                            <div>
                                <span className='mx-2'><FontAwesomeIcon icon="fa-brands fa-twitter" style={{color: '#1DA1F2'}}></FontAwesomeIcon></span><span><a href="https://twitter.com/jafecweb?s=21" className='text-decoration-none text-dark' rel='noopener noreferrer' target='_blank'>True Computing Technology</a></span>
                            </div>
                        </div>
                    </div>
                </div>
                <WhatsAppWidget phoneNumber="256751150234" />

                <div className='row bg-dark border rounded-bottom py-3'>
                    <div className='col-md-6'>
                       <small className='text-white justify-content-end'>&copy; {date} True Computing Technology</small>
                    </div>
                    <div className='col-md-6'>
                       <small className="text-white justify-content-start"><FontAwesomeIcon icon="fa-brands fa-whatsapp" style={{color: '#25D366'}}></FontAwesomeIcon> 0751150234</small>
                    </div>
                </div>

            </div>
        </>
    )
}