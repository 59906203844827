import React from 'react';
import './DisplayService.css';
import Item from './HelperScript';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCode } from '@fortawesome/free-solid-svg-icons'

export default function DisplayServices(){
    return (
        <div className="services" id='service'>
                <Item heading="Services"/>
                <div className="row text-center">
                <div className="col-md-4 py-1 px-4">
                    <div className="square"><FontAwesomeIcon icon={faCode} size="4x"/></div>
                    <div className="serv">
                    <h3>Website Development</h3>
                        <p>We develop web-site, Web-based software and Mobile application with intersection of privacy, and security. 
                        <span className='d-block' style={{color:'#050505'}}>
                            Search Engine Optimization (S.E.O) for websites to improve visibility and traffic on and 
                            ranking of web-site is a service TCT provides. 
                        </span>
                        </p>
                    </div>
                </div>
                <div className="col-md-4 py-1 px-auto">
                    <div className="serv">
                    <div className="square"><FontAwesomeIcon icon="fas fa-cogs" size='4x'/></div>
                    <h3>Computer Software</h3>
                        <p>TCT sales vast categories of licensed computer software ranging from Operating systems, 
                        Ms Office package, Accounting software packages, Anti-virus & malware softwares and many others.</p>
                    </div>
                </div>
                <div className="col-md-3 py-1 px-4">
                    <div className="serv">
                    <div className="square"><FontAwesomeIcon icon="fas fa-cloud" size='4x'/></div>
                    <h3>Organization Data backup & Recovery</h3>
                    <p>We offer secure digital organization data backup and recovery of all data types.</p>
                    </div>
                </div>
                </div>
                <div className='row text-center'>
                    <div className="col-md-4 py-1 px-4">
                        <div className="serv">
                        <div className="square"><FontAwesomeIcon icon="fas fa-users" size='4x'/></div>
                        <h3>ICT Consultation & I.T Audit</h3>
                            <p>We support clients with IT administration, 
                            Software deployment and upgrade, Hardware configuration and testing, User Support, network troubleshoot, and Training. 
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4 py-1 px-auto">
                        <div className="serv">
                        <div className="square"><FontAwesomeIcon icon="fas fa-globe" size='4x'/></div>
                        <h3>Office Computer Network</h3>
                            <p>TCT sets and provides office and home computer networks to clients. 
                            We deliver networks with best internet speed at affordable price.
                            </p>
                        </div>
                    </div>

                    <div className="col-md-3 py-1 px-4">
                        <div className="square"><FontAwesomeIcon icon="fas fa-shield" size='4x'/></div>
                        <div className="serv">
                        <h3>Digital Security Training & Audit</h3>
                        <p>We offer digital security training and audit for organizations, NGOs, CSOs, Journalists and others.</p>
                        </div>
                    </div>

                </div>
        </div>
    )
}