import React from 'react';
import Item from '../Services/HelperScript';
import {  } from '@fortawesome/free-solid-svg-icons'
import Carousel from 'react-bootstrap/Carousel'
import CardFormat from './DisplayFormat'; 
import './Products.css';

function ProductDisplay(){
    const poultry = {
        image: './products/poultry.png', 
        title: 'Poultry Record Manager', 
        description: 'Store and track poultry batch data and information with ease, accuracy and confidence.', 
        link: '#',
        name: 'Farm'
    }

    const rowl = {
        image: './products/rowl.png', 
        title: 'Remembering ones We Lost', 
        description: 'They were fathers, mothers, brothers, sisters and children. Each of them cherished by their families and communities.', 
        link: 'https://www.rememberingoneswelost.org',
        name: 'Client'
    }

    const property = {
        image: './products/modern-property.png', 
        title: 'Modern Property', 
        description: 'With the increasing urbanization and globalization, there is more need to find affordable, quality and modern housing within the urban dwellers.', 
        link: '#',
        name:'Property'
    }

    const BulkSMS = {
        image: './products/bulkSMS.png', 
        title: 'BulkSMS', 
        description: 'Send BulkSMS messages across all telecom networks in Uganda. Affordable, Reliable, and Instant messages to clients.', 
        link: '#',
        name: 'SMS'
    }

    // const bnsumba = {
    //     image: './products/bnsumba.png', 
    //     title: 'Bnsumba', 
    //     description: 'Pastor and Author Hillary Nsumba brief profile.', 
    //     link: 'https://www.bnsumba.net',
    //     name: 'Bnsumba'
    // }

    return (
        <div className='mx-1' id="product">
            <Item heading="Products"/>
            <Carousel className='productItems'>
                <Carousel.Item interval={5000}>
                <div className='row text-center py-2'>
                    <div className='col-md-4 py-1 px-2'>
                        <CardFormat product={poultry}/>
                    </div>
                    <div className='col-md-4 py-1 px-auto'>
                        <CardFormat product={rowl}/>
                    </div>
                    <div className='col-md-4 py-1 px-2'>
                        <CardFormat product={property}/>
                    </div>
                </div>
                </Carousel.Item>
                <Carousel.Item interval={5000}>
                <div className='row text-center py-2'>
                    <div className='col-md-4 py-1 px-2'>
                        <CardFormat product={BulkSMS}/>
                    </div>
                    <div className='col-md-4 py-1 px-auto'>
                        {/* <CardFormat product={bnsumba}/> */}
                    </div>
                    <div className='col-md-4 py-1 px-2'>
                        <CardFormat product={property}/>
                    </div>
                </div>
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

export default ProductDisplay;